<template>
    <div class="container-fluid">
        <div class="block-header">
                <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-lg-4 col-md-12">
                <feeds></feeds>
                <subscribe></subscribe>
                <followers></followers>
                <chat></chat>
                <design-team></design-team>
                <browser-stat></browser-stat>
                <stats-list></stats-list>
            </div>

            <div class="col-lg-4 col-md-12">
                <time-line></time-line>
                <list></list>
                <twitter-feed></twitter-feed>
                <ultima></ultima>
            </div>

            <div class="col-lg-4 col-md-12">
                <comments></comments>
                <to-do-list></to-do-list>
                <referrals></referrals>
                <pricing></pricing>
                <invoice></invoice>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'DataComponent',
    components: {
       BreadCrumb : () => import('@/components/BreadCrumb.vue'),
        'feeds': () => import('@/components/core/LucidFeeds.vue'),
        'time-line': () => import('@/components/core/Timeline.vue'),
        'comments': () => import('@/components/core/Comments.vue'),
        'subscribe': () => import('@/components/core/Subscribe.vue'),
        'list': () => import('@/components/core/InboxList.vue'),
        'to-do-list': () => import('@/components/core/ToDoList.vue'),
        'followers': () => import('@/components/core/Followers.vue'),
        'twitter-feed': () => import('@/components/core/TwitterFeed.vue'),
        'referrals': () => import('@/components/core/AnaliticalReferrals.vue'),
        'chat': () => import('@/components/core/AnaliticalChat.vue'),
        'ultima': () => import('@/components/core/Ultima.vue'),
        'pricing': () => import('@/components/core/Pricing.vue'),
        'design-team': () => import('@/components/core/DesignTeam.vue'),
        'browser-stat': () => import('@/components/core/BrowserStat.vue'),
        'invoice': () => import('@/components/core/Invoice.vue'),
        'stats-list': () => import('@/components/core/StatsList.vue'),
    }
}
</script>
